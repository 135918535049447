console.info('Hello world');


var btnSeeMore = document.querySelector('.sect__txtBtn');
btnSeeMore.addEventListener('click', () => {
    document.querySelector('.sect__seeMore').classList.toggle('sect__seeMore--actif');
    document.querySelector('.sect__seeMore').parentNode.classList.toggle('sect--seeMore');
})


// changement d'image 
const listLienVideo = ['./assets/images/v1.mp4', './assets/images/v2.mp4', './assets/images/v3.mp4', './assets/images/v4.mp4', './assets/images/v5.mp4', './assets/images/v6.mp4']
const listImgLien = ['./assets/images/caveavin1.png', './assets/images/caveavin2.png', './assets/images/futcave.png', './assets/images/lampe.png', './assets/images/sdb.png', './assets/images/japan1.png', './assets/images/japan2.png', './assets/images/japan3.png', './assets/images/horor.png', './assets/images/cabane.png', './assets/images/sw1.png', './assets/images/sw2.png', './assets/images/chair1.png', './assets/images/chair2.png', './assets/images/dino.png'];
const listNameImg = ['Cave à vin B/W', 'Cave à Vin', 'Fut / caves', 'Vol de Lampe', 'Salle de bain', 'Tori & Japan', 'Tori & Japan', 'Tori & Japan', 'Horror House', 'Trappeur house', 'Droide sonde SW', 'Droide sonde SW', 'old chair', 'old chair', 'T-rex mecha', 'T-rex mecha', 'T-rex mecha', 'T-rex mecha', 'T-rex mecha', 'T-rex mecha'];
let allListTxt = document.querySelectorAll('.sect__txt--sousTitre');
let allImgSlider = document.querySelectorAll('.slider__img')
let sliderBtn = document.querySelectorAll('.slider__btn');
let overlayImg = document.querySelector('.overlay__img');
let i = 0;



function changeImgBox(num, sect) {

    changeBoxItem(num);
    
    allImgSlider[sect].src = listImgLien[num];
    allListTxt[sect].innerHTML = listNameImg[num];
    sliderBtn[num].classList.add('slider__btn--actif');
    i++;
}

function changeBoxItem (num){
    sliderBtn.forEach(el => {
        if (num <= 4) {
            for (let i = 0; i <= 4; i++) {
                sliderBtn[i].classList.remove('slider__btn--actif');

            }
        } else if (num >= 5 && num <= 9) {
            for (let i = 5; i <= 9; i++) {
                sliderBtn[i].classList.remove('slider__btn--actif');

            }
        } else if (num >= 10 && num <= 11) {
            for (let i = 10; i <= 11; i++) {
                sliderBtn[i].classList.remove('slider__btn--actif');

            }
        } else if (num >= 12 && num <= 13) {
            for (let i = 12; i <= 13; i++) {
                sliderBtn[i].classList.remove('slider__btn--actif');

            }
        } else if (num >= 14 && num <= 19) {
            for (let i = 14; i <= 19; i++) {
                sliderBtn[i].classList.remove('slider__btn--actif');

            }
        }
    })

}


let allBoxImgSlider = document.querySelectorAll('.sect__sliderImage');
allBoxImgSlider[0].addEventListener('click', (e) => {
    document.querySelector('.overlay__img').classList.add('overlay__img--small')
})

let videoSrc = document.querySelector('.slider__video');
let overlayVideo = document.querySelector('#overlayVideo');

function changeVideo(num, num2, sect) {
    changeBoxItem(num);
   
    if(num == 14){
        
        document.querySelector('#boxImgVideo').classList.remove('slider__box--none');
        document.querySelector('#videoBox').classList.add('slider__box--none');
      
    }else{
        videoSrc.src = listLienVideo[num2];
        document.querySelector('#boxImgVideo').classList.add('slider__box--none');
        document.querySelector('#videoBox').classList.remove('slider__box--none');
    }


   
    allListTxt[sect].innerHTML = listNameImg[num];
    sliderBtn[num].classList.add('slider__btn--actif');
    i++;


}


sliderBtn[0].addEventListener('click', () => {
    changeImgBox(0, 0);
})
sliderBtn[1].addEventListener('click', () => {
    changeImgBox(1, 0);
})
sliderBtn[2].addEventListener('click', () => {
    changeImgBox(2, 0);
})
sliderBtn[3].addEventListener('click', () => {
    changeImgBox(3, 0);
})
sliderBtn[4].addEventListener('click', () => {
    changeImgBox(4, 0);
})
// environement
sliderBtn[5].addEventListener('click', () => {
    changeImgBox(5, 1);
})
sliderBtn[6].addEventListener('click', () => {
    changeImgBox(6, 1);
})
sliderBtn[7].addEventListener('click', () => {
    changeImgBox(7, 1);
})
sliderBtn[8].addEventListener('click', () => {
    changeImgBox(8, 1);
})
sliderBtn[9].addEventListener('click', () => {
    changeImgBox(9, 1);
})
/// modélisation 
sliderBtn[10].addEventListener('click', () => {
    changeImgBox(10, 2);
})
sliderBtn[11].addEventListener('click', () => {
    changeImgBox(11, 2);
})
/// texturing
sliderBtn[12].addEventListener('click', () => {
    changeImgBox(12, 3);
})
sliderBtn[13].addEventListener('click', () => {
    changeImgBox(13, 3);
})
/// animation
sliderBtn[14].addEventListener('click', () => {
    changeVideo(14, 0, 4)
})
sliderBtn[15].addEventListener('click', () => {
    changeVideo(15, 0, 4)
})
sliderBtn[16].addEventListener('click', () => {
    changeVideo(16, 1, 4)
})
sliderBtn[17].addEventListener('click', () => {
    changeVideo(17, 2, 4)
})
sliderBtn[18].addEventListener('click', () => {
    changeVideo(18, 3, 4)
})
sliderBtn[19].addEventListener('click', () => {
    changeVideo(19, 4, 4)
})



/// close overlay 

let btnCloseOverlay = document.querySelector('.overlay__btnClose')

btnCloseOverlay.addEventListener('click', (e) => {
    document.querySelector('.sect__overlay').classList.add('overlay__close');
    document.querySelector('body').style.overflowY = "scroll";
    document.querySelector('.overlay__img').classList.remove('overlay__img--small');


});

let allBoxImg = document.querySelectorAll('.sect__sliderImage');
let imgOverlay = document.querySelector('.overlay__img');
let txtOverlay = document.querySelector('.overlay__txt');
let io = 0;
allBoxImg.forEach(el => {



    el.addEventListener('click', (e) => {


        
            document.querySelector('body').style.overflowY = "hidden";
            console.log(e);
            document.querySelector('.sect__overlay').classList.remove('overlay__close');


            if (e.path[1].children[1].innerText === "Click me" || e.path[1].children[1].innerText === "Cliquez sur moi") {
                txtOverlay.innerHTML = e.path[1].children[2].innerText
            } else {
                txtOverlay.innerHTML = e.path[1].children[1].innerText
            }
            imgOverlay.src = e.path[0].currentSrc;
        

    })





})